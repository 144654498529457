/* CareerPage.css */

.career_title {
    font-family: 'Anek Bangla', sans-serif;
    font-size: 40px;
    font-weight: 700;
    margin: 20px;
  }
  
  .career_button {
    background-color: #ffffff !important;
    color: #000000;
    font-size: 16px;
    padding: 10px 20px;
    border: 2px solid #000000;
    border-radius: 30px;
    cursor: pointer;
    font-weight: bold;
    margin: 20px;
  }
  
  .career_button:hover {
    background-color: #987dea !important; /* Change background color on hover to purple */
    color: #ffffff; /* Change text color on hover */
  }
  
  .search-bar {
    width: 100%; /* Set the width to 100% */
    padding: 8px 12px; /* Adjust padding as needed */
    border-radius: 5px; /* Add border radius for rounded corners */
    border: 1px solid #000000; /* Add border */
  }
  
  .table {
    margin: 30px; /* Add some margin around the table */
  }
  
  .container-careers {
    padding: 20px;
    margin: 5%;
    text-align: center;
    align-items: center;
    justify-content: center;
  }
  /* Add more styles as necessary */
  