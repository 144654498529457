/* Common styles for both mobile and larger screens */

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&family=Open+Sans:ital@1&family=Pacifico&display=swap');

.image-with-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10%;
}

.image-container {
  width: 100%;
  max-width: 400px; /* Adjust the width of the image container based on your design */
  margin-bottom: 20px;
}

.image {
  width: 100%;
  height: auto;
}

.text {
  text-align: center;
  font-family: 'Anek Bangla', sans-serif;
  font-size: 16px;
}

.start-button {
  background-color: #6d6bd3;
  margin-top: 20px;
  color: #fff;
  font-size: 16px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
}

/* Media query for larger screens */
@media (min-width: 768px) {
  .image-with-text {
    flex-direction: row;
    justify-content: center;
  }

  .image-container {
    margin-right: 40px;
    margin-bottom: 0;
  }

  .text {
    text-align: left;
    font-size: 18px;
  }
}


.image-circle{
  border-radius: 70%;
  overflow: hidden;
  width: 50%;

}

.circular-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}


.title {
  font-family: 'Anek Bangla', sans-serif;
  text-align: center;
  font-size: 40px;
  font-weight: 700;
  margin-bottom: 20px;
}

.provide {
  background-color: #e3daff; /* Set the background color as needed */
  padding: 30px 10px;
}

.text-provide {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 30px;
  padding-left: 300px;
  padding-right: 300px;
}

.text-provide > div {
  margin-bottom: 20px;
}

.image-circle {
  width: 100%;
  max-width: 400px; /* Adjust the width of the image container based on your design */
  margin-bottom: 20px;
  border-radius: 10%;
  
 /* Adjust the size of the circular image as needed */
}

.circular-image {
  width: 100%;
  height: auto;
}

/* Media query for larger screens */
@media (min-width: 768px) {
  .text-provide {
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .text-provide > div {
    margin-bottom: 0;
    margin-right: 20px;
  }
}



/* Check us out  */

.custom-container {

  padding: 20px;
  border-radius: 10px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Align items to the top and bottom */
  height: 300px;
  margin-top: 50px;
}


.custom-title {
  font-size: 24px;
  font-weight: bold;
  
}

.custom-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1; /* Take remaining space in the container */
}

.custom-paragraph {
  font-size: 16px;
  margin-bottom: 20px;
}



/* footer */

.app-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
}

.main-content {
  flex: 1;
  margin-bottom: 20px;
}

.footer {
  background-color: #6d6bd3;
  padding: 10px;
  display: flex;
  justify-content: space-between;
 
}

.left-section,
.right-section {
  flex: 1;
}

.text-container {
  color:white;
  padding: 5px;
  font-weight: bold;
  margin: 10%;
}

.bottom-text{
  color: white;
  margin-left: 11%;
}