/* Add custom CSS here */
.Auth-form-container {
    background-color: #E3DAFF;
  }
  
  .Auth-form-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
  }
  
  .Auth-form {
    width: 420px;
    box-shadow: rgb(0 0 0 / 16%) 1px 1px 10px;
    padding-top: 30px;
    padding-bottom: 20px;
    border-radius: 8px;
    background-color: #fcfdff;
  }
  
  .Auth-form-content {
    padding-left: 12%;
    padding-right: 12%;
  }
  
  .Auth-form-title {
    text-align: center;
    margin-bottom: 1em;
    font-size: 24px;
    color: #6D6BD3;
    font-weight: 800;
  }
  
  label {
    font-size: 14px;
    font-weight: 600;
    color: #6D6BD3;
  }

  button{
    background-color:#6D6BD3 !important;
  }


  

  

